<template>
  <b-row class="match-height">
    <b-col cols="12" md="12">
      <b-alert
          v-if="!dataList.length > 0"
          variant="warning"
          show
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" /> Makina kartınız bulunmamaktadır.
        </div>
      </b-alert>
    </b-col>
    <b-col
      v-for="machine in dataList"
      :key="machine.id"
      cols="12"
      md="6"
    >
      <machine-card
        :machine="machine"
        :is-report="isReport"
      />
    </b-col>
    <b-col
      v-if="!isReport"
      cols="12"
      md="6"
    >
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, VBToggle, BAlert,
} from 'bootstrap-vue'
import MachineCard from '@/views/Admin/Machines/CustomerMachines/MachineCard.vue'

export default {
  name: 'CustomerMachines',
  components: {
    BAlert,
    BRow, BCol, MachineCard,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    isReport: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataList() {
      return this.$store.getters['customerMachines/dataList']
    },
    saveData() {
      return this.$store.getters['customerMachines/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.getData()
      } else if (val.status === false) {
        this.getData()
      }
    },
  },
  created() {
    this.getData()
  },
  data(){
    return {
      userData: JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
    getData() {
      this.$store.dispatch('customerMachines/getDataList', {
        select: [
          'customer_machines.id AS id',
          'products.title AS product',
          'customer_machines.title AS title',
          'customer_machines.serial AS serial',
          'customer_machines.status AS status',
          'customer_machines.notes AS notes',
          'customer_machines.warranty_sdate AS warranty_sdate',
          'customer_machines.warranty_edate AS warranty_edate',
          'customer_machines.head_warranty_sdate AS head_warranty_sdate',
          'customer_machines.head_warranty_edate AS head_warranty_edate',
          'customer_addresses.title AS address_title',
        ],
        where: {
          'customer_machines.id_customers': this.userData.id_customers
        },
      })
    },
  },
}
</script>
